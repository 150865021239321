@font-face {
  font-family: 'Garet-Book';
  src: url('./assets/garet/Garet-Book.otf');
  src: url('./assets/garet/Garet-Book.ttf');
  src: url('./assets/garet/Garet-Book.woff');
  src: url('./assets/garet/Garet-Book.woff2');
}
@font-face {
  font-family: 'Garet-Book-Heavy';
  src: url('./assets/garet/Garet-Heavy.otf');
  src: url('./assets/garet/Garet-Heavy.ttf');
  src: url('./assets/garet/Garet-Heavy.woff');
  src: url('./assets/garet/Garet-Heavy.woff2');
}
:root{
  --darkGreen: #526345;
  --Green: #8fac5f;
  --lightGreen: #d7e1c3;
}





@media only screen and (max-width: 600px) {
  html,body{
    overflow-x: hidden;
    scroll-behavior: smooth;
    overscroll-behavior: none;
  }
  .App{
    .Btn{
      padding-left:2.5%;
      padding-right:2.5%;
      padding-top:0.25%;
      padding-bottom:0.25%;
      border-radius:5pt 5pt;
      color: inherit;
      text-decoration: none;
  
    }
    .Btn:visited{
        color:initial;
        text-decoration: none;
  
    }
    .Descriptor{
      color:var(--Green);
      font-size:20px;
      font-family:'Garet-Book-Heavy';
      text-transform: uppercase;
    }
    .Title{
      color:var(--darkGreen);
      font-size:45px;
      line-height:1.2;
      font-family:'Garet-Book-Heavy';
      text-transform: uppercase;
      margin:0;
  
    }
    .Text{
      color:var(--darkGreen);
      font-size:15px;
      font-family:'Garet-Book'
    }
    
    .Nav{
      z-index:50;
      display:none;
      width:100%;
      height:60px;
      display:flex;
      align-items: center;
      justify-content: space-between;
      position:fixed;
      overflow:hidden;
      background-color: white;
      -webkit-box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.5); 
        box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.5);

      #Nav-Collapse{
        z-index:100;
        display:flex;
        float:right;
        margin-right: 10%;
        justify-content: center;
        font-size:30px;
        color:white;
        background-color:none;
        border-radius:50%;
        padding:5px;
        background-color:var(--darkGreen);
        

      }
      &-Logo{
        z-index:100;
        filter:none;
        /*filter:brightness(100) grayscale(1) contrast(1);*/
        float:left;
        height:500%;
        font-size:50%;
        margin-top:15px;
        &.active{
          
        }
        
      }
      &-Content{
        display:none;
        position:fixed;
        font-family:'Garet-Book', sans-serif;
        width:100%;
        top:0;
        overflow:hidden;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        color:var(--darkGreen);
        padding-top:60px;
        &.active{
          display:flex;
          background-color:white;
          -webkit-box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.5); 
        box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.5);
        }
      }
      
      &-Item{
        background-color:white;
        text-align: center;
        width:100%;
        padding:20px;
        font-size:15pt;
        
      }
      a, a:visited {
        color: inherit;
        text-decoration: none;
       }
      
    }
    
    .Home{
      overflow: hidden;
      .Landing{
        position:relative;
        width:100%;
        height:700px;
        display:flex;
        align-items:flex-start;
        justify-content: center;
        &-Img{
          object-fit: cover;
          z-index:1;
          position:absolute;
          width:100%;
          height:700px;
          filter:brightness(.5) contrast(.6);
        }
        &-Content{
          z-index:2;
          display:flex;
          align-items:center;
          align-self:center;
          justify-content: flex-start;
          position:absolute;
          color:white;
          width:80%;
          display:flex;
          flex-direction:column;
          text-align:left;
          align-items:flex-start;
  
          
        }
        &-Title{
          font-family:'Garet-Book-Heavy';
          font-size:70px;
          width:90%;
          line-height: 1.05;
          margin:0;
          
        }
        &-Text{
          font-family:'Garet-Book';
          font-size:15px;
          width:90%;
          margin:0;
          margin-top:10px;
        }
        &-Btn{
          font-family:'Garet-Book';
          background-color: white;
          color:var(--darkGreen);
          font-size:20px;
          margin-top:30px;
        }
      }
      .Services{
        overflow: hidden;
        position:relative;
        width:100%;
        display:flex;
        align-items:center;
        justify-content:flex-start;
        flex-direction: column;
        padding-top:50px;
        padding-bottom:50px;
        &-Descriptor{
          text-align:center;
          
        }
        &-Title{
          text-align:center;
        }
        &-Text{
          text-align:center;
          width: 70%;
          margin-bottom:40px;
        }
        &-Content{
          width:100%;
          align-self: flex-start;
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          overflow-x: scroll;
            scroll-behavior: smooth;
            scrollbar-width: none;
            ::-webkit-scrollbar {
              display: none;
           }
          .Services-Card{
            animation:none !important;
            transition: none;
            border: lightgray 2px solid;
            display:flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-left:10px;
            margin-right:10px;
            border-radius: 5pt 5pt;
            flex: 0 0 280px; 
            height:300px;
            -webkit-box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.5); 
            box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.5);
            
            .Card-Img{
              width:20%;
              border-radius:5pt 5pt;
            }
            .Card-Title{
              color:var(--darkGreen);
              text-align: center;
              width:80%;
            }
            .Card-Text{
              margin:0;
              color:var(--darkGreen);
              text-align: center;
              width:70%;
            }
          }
        }
      }

      .About{
        position:relative;
        width:100%;
        height:600px;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction: column;
        background-color:var(--lightGreen);
        &-Content{
          text-align:center;
          width:60%;
        }
        &-Img{
          width:30%;
        }
        &-Descriptor{
          text-align:center;
        }
        &-Title{
          text-align:center;
        }
        &-Text{
          text-align:center;
          width: 100%;
        }
      }
      .Contact{
        position:relative;
        width:100%;
        height:800px;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction: column;
        &-Card{
          width:80%;
          display:flex;
          align-items:center;
          justify-content:center;
          flex-direction: column;
          border-color: var(--darkGreen);
          background-color:var(--darkGreen);
          padding-bottom:10%;
          -webkit-box-shadow: 0px 0px 25px -5px rgba(0,0,0,0.5); 
            box-shadow: 0px 0px 25px -5px rgba(0,0,0,0.5);
          h4{
            color:white;
            font-size:20px;
            font-family:'Garet-Book-Heavy';
            margin-bottom:5%;
          }
          p{
            color:white;
            font-size:15px;
            font-family:'Garet-Book';
            margin:0;
          }
        }
        &-Content{
          text-align: center;
          width:80%;
        }
        &-Descriptor{
          text-align: center;
        }
        &-Title{
          text-align: center;
        }
        &-Text{
          text-align: center;
          width: 100%;
        }
      }
      


      
    }
    .Quotes{
      
      
      .Landing{
        position:relative;
        width:100%;
        height:700px;
        display:flex;
        align-items:flex-start;
        justify-content: center;
        &-Img{
          object-fit: cover;
          z-index:1;
          position:absolute;
          width:100%;
          height:700px;
          filter:brightness(.5) contrast(.6);
        }
        &-Content{
          z-index:2;
          display:flex;
          align-items:center;
          align-self:center;
          justify-content: flex-start;
          position:absolute;
          color:white;
          width:80%;
          display:flex;
          flex-direction:column;
          text-align:left;
          align-items:flex-start;
  
          
        }
        &-Title{
          font-family:'Garet-Book-Heavy';
          font-size:60px;
          width:90%;
          line-height: 1.05;
          margin:0;
          
        }
        &-Text{
          font-family:'Garet-Book';
          font-size:15px;
          width:90%;
          margin:0;
          margin-top:10px;
        }
        &-Btn{
          font-family:'Garet-Book';
          background-color: white;
          color:var(--darkGreen);
          font-size:25px;
          margin-top:30px;
        }
      }
      .Section{
        position:relative;
        width:100%;
        height:750px;
        padding-bottom:50px;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction: row;
        background-color:white;
        display:flex;
        flex-direction:row;
        justify-content:space-evenly;
        &-Infographic{
          display:none;
          width:30%;
          height:90%;
          background-color:var(--lightGreen);
          
          &-Title{
            width:20%;
            top:-30px;
            line-height:1.2;
            margin-left:-20px;
            color:var(--darkGreen);
            position:absolute;
            font-size:60px;
            font-family: 'Garet-Book-Heavy',sans-serif;
          }
          &-Content{
            position:absolute;
            top:250px;
            display:flex;
            flex-direction: column;
            img{
              position:relative;
              width:125px;
              margin-left:-50px;
            }
            &-Group{
              display:flex;
              flex-direction: row;
              margin-bottom:25px;
              &-Text{
                display:flex;
                flex-direction: column;
                h3{
                  color:var(--darkGreen);
                  margin:0;
                  margin-top:20px;
                  font-size:30px;
                  font-family: 'Garet-Book-Heavy',sans-serif;
                }
                span{
                  width:50%;
                  color:var(--darkGreen);
                  font-family: 'Garet-Book',sans-serif;
                }
              }
          }
            
          }
  
        }
        &-Appreciation{
          width:80%;
          display:flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size:50px;
          color:var(--darkGreen);
          h2{
            font-size:40px;
            text-align:center;
            font-family: 'Garet-Book-Heavy';
          }
          span{
            font-size:15px;
            text-align:center;
            font-family: 'Garet-Book';
          }
        }
        &-Form{
          width:80%;
          display:flex;
          flex-direction: column;
          [type="submit"]{
            background-color:var(--darkGreen);
            color:white;
            border:none;
            margin-top: 5%;
            font-size:20px;
            font-family: "Garet-Book-Heavy", sans-serif;
            padding:10px;
          }
          &-Group{
            display:flex;
            flex-direction:column;
            .Row{
              display:flex;
              flex-direction:row;
              justify-content: space-between;
              width:100%;
            }
            &-Title{
              color:var(--darkGreen);
              font-family:'Garet-Book-Heavy';
            }
            label{
              display: flex;
              flex-direction: column;
              color:var(--darkGreen);
              font-family:'Garet-Book';
            }
          }
          &-Item{
            border:solid var(--darkGreen) 1px;
            border-radius:5pt 5pt;
            color:var(--darkGreen);
            font-size:20px;
            font-family:'Garet-Book';
            
          }
  
          #FirstName{
            width:48%;
            
          }
          #LastName{
            width:50%;
            
          }
          #City{
            width:50%;
            margin-top:2.5%;
          }
          #State{
            width:20%;
            margin-top:2.5%;
          }
          #Zipcode{
            width:30%;
            margin-top:2.5%;
          }
          #Phone{
            width:50%;
          }
        }
      }
    }
    .Footer{
      position:relative;
      width:100%;
      display:flex;
      align-items:center;
      justify-content:center;
      flex-direction: column;
      background-color:#526345;
      padding-top:25px;
      padding-bottom:35px;
      text-transform: uppercase;
      &-Row{
        width:100%;
        display:flex;
        align-items:center;
        justify-content:space-evenly;
        flex-direction: row;
      }
      &-Column{
        display:flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        font-family: 'Garet-Book', sans-serif;
        
        
        &-Item{
         
          display:flex;
          text-align:left;
          
          width:100%;
          font-size:15px;
          color: var(--lightGreen);
        }
        
      }
      a, a:visited {
        
        text-decoration: none;
       }
       h4{
        font-size:20px;
        margin-bottom:5px;
        margin-top:25px;
        text-align:left;
        color: white;
      }
      &-SocialIcons{
        color: var(--lightGreen);
        font-size:20px;
        display:flex;
        flex-direction: row;
        width:100%;
        justify-content: space-evenly;
        align-items: center;
        
      }
    }
  }
}





























@media only screen and (min-width: 601px) {

  html,body{
    scroll-behavior: smooth;
    overscroll-behavior: none;
  }
  .App{
    .Btn{
      padding-left:2.5%;
      padding-right:2.5%;
      padding-top:0.25%;
      padding-bottom:0.25%;
      border-radius:5pt 5pt;
      color: inherit;
      text-decoration: none;
  
    }
    .Btn:visited{
        color:initial;
        text-decoration: none;
  
    }
    .Descriptor{
      color:var(--Green);
      font-size:25px;
      font-family:'Garet-Book-Heavy';
      text-transform: uppercase;
    }
    .Title{
      color:var(--darkGreen);
      font-size:60px;
      font-family:'Garet-Book-Heavy';
      text-transform: uppercase;
      margin:0;
  
    }
    .Text{
      color:var(--darkGreen);
      font-size:22.5px;
      font-family:'Garet-Book'
    }
    .Nav{
      z-index:10;
      width:100%;
      height:60px;
      background-color:white;
      display:flex;
      align-items: center;
      justify-content: space-between;
      position:fixed;
      &-Logo{
        float:left;
        height:500%;
        margin-top:15px;
        
      }
      #Nav-Collapse{
        display:none;
      }
      &-Content{
        margin-right:1.25%;
        float:right;
        font-family:'Garet-Book', sans-serif;
        width:40%;
        display:flex;
        flex-direction: row;
        justify-content: space-around;
        color:var(--darkGreen);
        .Btn{
          background-color: var(--darkGreen);
          color:white !important;
        }
      }
      &-Item{
        font-size:15pt;
        transition:filter 0.25s;
        &:hover{
          filter:brightness(1.5);
        }
        
      }
      a, a:visited {
        color: inherit;
        text-decoration: none;
       }
      
    }
    .Home{
      .Landing{
        position:relative;
        top:60px;
        width:100%;
        height:800px;
        display:flex;
        align-items:flex-start;
        justify-content: center;
        &-Img{
          object-fit: cover;
          z-index:1;
          border-radius: 50pt 50pt;
          position:absolute;
          width:95%;
          height:800px;
          filter:brightness(.5) contrast(.6);
        }
        &-Content{
          z-index:2;
          display:flex;
          align-items:center;
          align-self:center;
          justify-content: flex-start;
          position:absolute;
          color:white;
          width:80%;
          display:flex;
          flex-direction:column;
          text-align:left;
          align-items:flex-start;
  
          
        }
        &-Title{
          font-family:'Garet-Book-Heavy';
          font-size:75px;
          width:550px;
          line-height: 1.05;
          margin:0;
          
        }
        &-Text{
          font-family:'Garet-Book';
          font-size:30px;
          width:50%;
          margin:0;
          margin-top:10px;
        }
        &-Btn{
          font-family:'Garet-Book';
          background-color: white;
          color:var(--darkGreen);
          font-size:25px;
          margin-top:30px;
          border: solid 3px transparent;
          transition:color 0.25s, background-color 0.25s, border 0.25s;
          &:hover{
            
            background-color:transparent;
            color:white;
            border:solid 3px white;
          }
        }
      }
      .Services{
        position:relative;
        width:100%;
        display:flex;
        align-items:center;
        justify-content:flex-start;
        flex-direction: column;
        padding-top:100px;
        padding-bottom:50px;
        &-Descriptor{
          text-align:center;
        }
        &-Title{
          text-align:center;
        }
        &-Text{
          text-align:center;
          width: 30%;
          margin-bottom:50px;
        }
        &-Content{
          width: 65%;
          display:flex;
          flex-direction: row;
          justify-content: space-evenly;
          
          flex-wrap:wrap;
          .Services-Card{
            border: lightgray 2px solid;
            margin-bottom:2.5%;
            display:flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 5pt 5pt;
            width:30%;
            height:325px;
            -webkit-box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.5); 
            box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.5);
            
            .Card-Img{
              width:20%;
              border-radius:5pt 5pt;
            }
            .Card-Title{
              color:var(--darkGreen);
              text-align: center;
              width:80%;
            }
            .Card-Text{
              margin:0;
              color:var(--darkGreen);
              text-align: center;
              width:70%;
            }
          }
        }
      }
      .About{
        position:relative;
        width:100%;
        height:700px;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction: row;
        background-color:var(--lightGreen);
        &-Content{
          width:40%;
        }
        &-Img{
          width:30%;
        }
        &-Descriptor{
          text-align:left;
        }
        &-Title{
          text-align:left;
        }
        &-Text{
          text-align:left;
          width: 80%;
          
        }
      }
      .Contact{
        position:relative;
        width:100%;
        height:800px;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction: row;
        &-Card{
          width:25%;
          display:flex;
          align-items:center;
          justify-content:center;
          flex-direction: column;
          background-color:var(--darkGreen);
          padding-bottom:2.5%;
          -webkit-box-shadow: 0px 0px 25px -5px rgba(0,0,0,0.5); 
            box-shadow: 0px 0px 25px -5px rgba(0,0,0,0.5);
          h4{
            color:white;
            font-size:35px;
            font-family:'Garet-Book-Heavy';
            margin-bottom:5%;
          }
          p{
            color:white;
            font-size:22.5px;
            font-family:'Garet-Book';
            margin:0;
          }
        }
        &-Content{
          width:40%;
        }
        &-Descriptor{
          text-align:left;
        }
        &-Title{
          text-align:left;
        }
        &-Text{
          text-align:left;
          width: 80%;
        }
      }
      
    }
  
    .Quotes{
      
      
      .Landing{
        position:relative;
        top:60px;
        width:100%;
        height:800px;
        display:flex;
        align-items:flex-start;
        justify-content: center;
        &-Img{
          object-fit: cover;
          z-index:1;
          border-radius: 50pt 50pt;
          position:absolute;
          width:95%;
          height:800px;
          filter:brightness(.5) contrast(.6);
        }
        &-Content{
          z-index:2;
          display:flex;
          align-items:center;
          align-self:center;
          justify-content: flex-start;
          position:absolute;
          color:white;
          width:80%;
          display:flex;
          flex-direction:column;
          text-align:left;
          align-items:flex-start;
  
          
        }
        &-Title{
          font-family:'Garet-Book-Heavy';
          font-size:75px;
          width:40%;
          line-height: 1.05;
          margin:0;
          
        }
        &-Text{
          font-family:'Garet-Book';
          font-size:30px;
          width:50%;
          margin:0;
          margin-top:10px;
        }
        &-Btn{
          font-family:'Garet-Book';
          background-color: white;
          color:var(--darkGreen);
          font-size:25px;
          margin-top:30px;
        }
      }
      .Section{
        position:relative;
        width:100%;
        height:900px;
        padding-bottom:50px;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction: row;
        background-color:white;
        display:flex;
        flex-direction:row;
        justify-content:space-evenly;
        &-Infographic{
          margin-top:120px;
          width:500px;
          height:650px;
          background-color:var(--lightGreen);
          
          &-Title{
            width:390px;
            margin-top:-30px;
            line-height:1.2;
            margin-left:-20px;
            color:var(--darkGreen);
            font-size:55px;
            font-family: 'Garet-Book-Heavy',sans-serif;
          }
          &-Content{
            position:absolute;
            margin-top:-25px;
            display:flex;
            flex-direction: column;
            img{
              position:relative;
              width:100px;
              object-fit: contain;
              margin-top:30px;
              margin-left:-50px;
            }
            &-Group{
              display:flex;
              flex-direction: row;
              margin-bottom:25px;
              &-Text{
                display:flex;
                width:500px;
                flex-direction: column;
                h3{
                  color:var(--darkGreen);
                  margin:0;
                  margin-top:20px;
                  margin-left:20px;
                  font-size:30px;
                  font-family: 'Garet-Book-Heavy',sans-serif;
                }
                span{
                  width:80%;
                  margin-left:20px;
                  color:var(--darkGreen);
                  font-family: 'Garet-Book',sans-serif;
                }
              }
          }
            
          }
  
        }
        &-Appreciation{
          width:30%;
          display:flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size:50px;
          color:var(--darkGreen);
          h2{
            font-size:40px;
            text-align:center;
            font-family: 'Garet-Book-Heavy';
          }
          span{
            font-size:15px;
            text-align:center;
            font-family: 'Garet-Book';
          }
        }
        &-Form{
          margin-top:120px;
          width:500px;
          display:flex;
          flex-direction: column;
          [type="submit"]{
            background-color:var(--darkGreen);
            color:white;
            border:none;
            margin-top: 5%;
            font-size:20px;
            font-family: "Garet-Book-Heavy", sans-serif;
            padding:10px;
          }
          &-Group{
            display:flex;
            flex-direction:column;
            .Row{
              display:flex;
              flex-direction:row;
              justify-content: space-between;
              width:100%;
            }
            &-Title{
              color:var(--darkGreen);
              font-family:'Garet-Book-Heavy';
            }
            label{
              display: flex;
              flex-direction: column;
              color:var(--darkGreen);
              font-family:'Garet-Book';
            }
          }
          &-Item{
            border:solid var(--darkGreen) 1px;
            border-radius:5pt 5pt;
            color:var(--darkGreen);
            font-size:20px;
            font-family:'Garet-Book';
            
          }
  
          #FirstName{
            width:48%;
            
          }
          #LastName{
            width:50%;
            
          }
          #JobType{
            width:60%;
          }
          #JobDescription{
            width:100%;
            margin-top:2%;
            textarea{
              resize: none;
              width:100%;
              height:140px;
            }
            
            
          }
          #City{
            width:60%;
            margin-top:2%;
          }
          #State{
            width:10%;
            margin-top:2%;
          }
          #Zipcode{
            width:26%;
            margin-top:2%;
          }
          #Phone{
            width:37%;
          }
          #Email{
            width:61%;
          }
        }
      }
    }
    .Footer{
      position:relative;
      width:100%;
      display:flex;
      align-items:center;
      justify-content:center;
      flex-direction: column;
      background-color:#526345;
      padding-top:15px;
      padding-bottom:25px;
      text-transform: uppercase;
      &-Row{
        width:100%;
        display:flex;
        align-items:center;
        justify-content:space-evenly;
        flex-direction: row;
      }
      &-Column{
        display:flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        font-family: 'Garet-Book', sans-serif;
        
        
        &-Item{
         
          display:flex;
          text-align:left;
          
          width:100%;
          font-size:15px;
          color: var(--lightGreen);
        }
        
      }
      a, a:visited {
        
        text-decoration: none;
       }
       h4{
        font-size:20px;
        margin-bottom:5px;
        margin-top:25px;
        text-align:left;
        color: white;
      }
      &-SocialIcons{
        
        color: var(--lightGreen);
        font-size:20px;
        display:flex;
        flex-direction: row;
        width:100%;
        justify-content: space-evenly;
        align-items: center;
        a, a:visited{
          color:white;
        }
        
      }
    }
  }
  }
  
  
  